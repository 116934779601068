import React, {useState} from "react";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {withTranslation} from "react-i18next";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";

import history from "../../helper/browserHistory"

const DEFAULT_USER_VALUES = {
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    passwordRepeat: "",
    admin: false,
    roles: [],
    rolesEdit: false
}


function handleInputChange(name, e, setUser) {
    let value = e.target.value;
    setUser(prevState => ({...prevState, [name]: value}));
}

function showErrorMessage(message, i18n) {
    return <Message
        error
        header={i18n.t('login:alert.login_failed')}
        content={message}
    />
}


const RegisterPage = (props) => {
    const [user, setUser] = useState(DEFAULT_USER_VALUES);
    const [errorRegister] = useState({errorUsername: false, errorPassword: false, errorEmail: false});
    const [errorMessage, setErrorMessage] = useState('');
    const {i18n} = props;

    return (<Segment className={"background-secondary text-secondary border-primary"}>
            <h1 className={"header-primary"}>{i18n.t("login:user.register")}</h1>
            <Form className={"background-secondary text-secondary"}
                  error={errorRegister.errorPassword || errorRegister.errorUsername || errorRegister.errorEmail}>
                <Form.Group widths='equal'>
                    <Form.Field className={"background-secondary text-secondary"}>
                        <label className={"header-primary"}>{i18n.t("login:user.first_name")}</label>
                        <input className={"background-primary text-primary border-primary"}
                               value={user.firstName}
                               onChange={e => handleInputChange('firstName', e, setUser)}/>
                    </Form.Field>
                    <Form.Field>
                        <label className={"header-primary"}>{i18n.t("login:user.last_name")}</label>
                        <input className={"background-primary text-primary border-primary"}
                               value={user.lastName}
                               onChange={e => handleInputChange('lastName', e, setUser)}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field className={"background-secondary text-secondary"} error={errorRegister.errorUsername}>
                        <label className={"header-primary"}>{i18n.t("login:user.username")}</label>
                        <input className={"background-primary text-primary border-primary"}
                               value={user.username}
                               onChange={e => handleInputChange('username', e, setUser)}/>
                    </Form.Field>
                    <Form.Field className={"background-secondary text-secondary"} error={errorRegister.errorEmail}>
                        <label className={"header-primary"}>{i18n.t("login:user.email")}</label>
                        <input className={"background-primary text-primary border-primary"}
                               value={user.email}
                               type='email'
                               onChange={e => handleInputChange('email', e, setUser)}/>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field className={"background-secondary text-secondary"} error={errorRegister.errorPassword}>
                        <label className={"header-primary"}>{i18n.t("login:user.password")}</label>
                        <input className={"background-primary text-primary border-primary"}
                               value={user.password}
                               type='password'
                               onChange={e => handleInputChange('password', e, setUser)}/>
                    </Form.Field>
                    <Form.Field className={"background-secondary text-secondary"} error={errorRegister.errorPassword}>
                        <label className={"header-primary"}>{i18n.t("login:user.confirm_password")}</label>
                        <input className={"background-primary text-primary border-primary"}
                               value={user.passwordRepeat}
                               type='password'
                               onChange={e => handleInputChange('passwordRepeat', e, setUser)}/>
                    </Form.Field>
                </Form.Group>
                {showErrorMessage(errorMessage, i18n)}
            </Form>
            <Button className={"button-primary"}
                    onClick={() => {
                        setErrorMessage('Momentan ist leider keine Regestrierung möglich');
                        /*  if (checkInput(user).success) {

                              userStore.registerUser(user).then((res) => {
                                      if (res.success) {
                                          history.push("/" + i18n.language + '/login');
                                      } else {
                                          setErrorMessage(res.message);
                                          setErrorRegister({errorUsername: true});
                                      }
                                  }

                              );

                          } else {


                              switch (checkInput(user).errorCode) {
                                  case 2:
                                      setErrorMessage(i18n.t('login:alert.password_not_match'))
                                      setErrorRegister({errorPassword: true});
                                      break;
                                  case 1:
                                      setErrorMessage(i18n.t('login:alert.valid_add_new_user'))
                                      setErrorRegister({errorPassword: true});
                                      break;
                                  case 3:
                                      setErrorMessage(i18n.t('login:alert.error_email'))
                                      setErrorRegister({errorEmail: true});
                                      break;
                                  default:
                                      break;
                              }

                          }
                               */
                    }}
                    type='submit'>{i18n.t("login:user.register")}</Button>
            <Button className={"button-primary"}
                    floated={"right"}
                    onClick={() => history.push("/" + i18n.language.replace("_", "-") + "/login")}>{i18n.t("common:common.back")}</Button>
        </Segment>
    )
}

export default withTranslation(['login', 'common'])(RegisterPage)